/**
 * Convert string to title case
 * @param {*} val Value to format
 * @returns {*}
 */
const toTitleCase = val => {
  if (typeof val !== 'string') {
    return val
  }
  return val.toLowerCase().replace(/\b\w/g, char => char.toUpperCase())
}

module.exports = toTitleCase
