import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import toHandle from 'utils/to-handle'
import { FiChevronUp } from '@react-icons/all-files/fi/FiChevronUp'
import { FiChevronDown } from '@react-icons/all-files/fi/FiChevronDown'
import UnstyledButton from 'components/button/unstyled-button'

const CategorySquare = ({ category, subcategory, total, ...props }) => {
  const [showing, setShowing] = useState(undefined)

  useEffect(() => {
    if (subcategory && subcategory.length > 4) {
      setShowing(false)
    }
  }, [subcategory])

  const categoryLink = category === 'All Used Equipment' ? 'all' : toHandle(category)

  const id = `used-${category.toLowerCase().replace(' ', '-')}-category-title`
  return (
    <div {...props}>
      <Title>
        <Link id={id} to={`/used-equipment/${categoryLink}/`}>
          {category}
        </Link>{' '}
        <Count>({total})</Count>
      </Title>
      {subcategory && (
        <Ul>
          {subcategory.map((subcategory, key) => (
            <Li key={key} hidden={key >= 4 && !showing}>
              <Link to={`/used-equipment/${categoryLink}/${toHandle(subcategory.subcategory)}/`}>
                {subcategory.subcategory}
              </Link>
              <Count> ({subcategory.total})</Count>
            </Li>
          ))}
        </Ul>
      )}
      {showing !== undefined && (
        <ToggleShowing
          aria-expanded={showing}
          aria-labelledby={id}
          onClick={() => setShowing(!showing)}
        >
          {showing ? (
            <>
              Show less <FiChevronUp aria-hidden='true' focusable='false' role='presentation' />
            </>
          ) : (
            <>
              Show more <FiChevronDown aria-hidden='true' focusable='false' role='presentation' />
            </>
          )}
        </ToggleShowing>
      )}
    </div>
  )
}

CategorySquare.propTypes = {
  category: PropTypes.string.isRequired,
  subcategory: PropTypes.arrayOf(
    PropTypes.shape({
      subcategory: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
    })
  ),
  total: PropTypes.number.isRequired,
}

const Title = styled.h3`
  border-bottom: 1px solid #d7d7d7;
  font-size: 1.3em;
  font-weight: 600;
  margin: 0 0 5px;
  max-width: none;
  padding: 0 0 10px;
  text-transform: none;

  a {
    color: #377539;
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }

  @media (min-width: 800px) {
    font-size: 1.1em;
  }
`

const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const Li = styled.li`
  display: ${props => (props.hidden ? 'none' : 'list-item')};
  font-size: 1.1em;
  line-height: 1.3;
  margin: 12px 0 0;
  padding: 0;

  a {
    color: #377539;
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }

  @media (min-width: 800px) {
    li {
      font-size: 1em;
      margin: 10px 0 0;
    }
  }
`

const Count = styled.span`
  color: #999;
  font-size: 0.85em;
  font-weight: 400;
  padding-left: 1px;
`

const ToggleShowing = styled(UnstyledButton)`
  color: #377539;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-style: italic;
  margin-top: 18px;

  :hover,
  :focus {
    text-decoration: underline;
  }

  svg {
    font-size: 0.9em;
    margin-top: 2px;
    vertical-align: top;
  }

  @media (min-width: 800px) {
    font-size: 0.9em;
    margin-top: 15px;
  }
`

export default React.memo(CategorySquare)
