const toTitleCase = require('./to-title-case')

const getTypeName = type => {
  switch (type) {
    case 'ENGINE_HOURS':
      return 'engine hours'
    case 'SEPARATOR_HOURS':
      return 'separator hours'
    case 'BALES':
      return 'bales'
    case 'ACRES':
      return 'acres'
    case 'MILES':
      return 'miles'
    default:
      // This field is an enum and non-nullable, so we should never reach this point
      return null
  }
}

/**
 * Format used equipment meter type
 * @param {('ENGINE_HOURS'|'SEPARATOR_HOURS'|'BALES'|'ACRES'|'MILES')} type Meter type
 * @param {object} [options={}] Options
 * @param {boolean} [options.titleCase=false] Title case
 * @returns {string}
 */
const formatUsedEquipmentMeterType = (type, options = {}) => {
  const { titleCase = false } = {
    ...options,
  }

  const typeName = getTypeName(type)

  return titleCase ? toTitleCase(typeName) : typeName
}

module.exports = formatUsedEquipmentMeterType
