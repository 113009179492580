import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { addEventListener } from 'consolidated-events'
import noop from 'utils/noop'

let removeMouseDown = noop
let removeMouseUp = noop

const ClickOut = ({ children, onClickOut, onClickIn, useCapture, ...props }) => {
  const [childNode, setChildNode] = useState(undefined)

  useEffect(() => {
    addMouseDownEventListener(useCapture)

    return () => {
      removeMouseDown()
      removeMouseUp()
    }
  }, [childNode])

  const onMouseDown = () => {
    removeMouseUp = addEventListener(document, 'mouseup', onMouseUp, { capture: useCapture })
  }

  const onMouseUp = e => {
    const isDescendantOfRoot = childNode && childNode.contains(e.target)

    removeMouseUp()
    removeMouseUp = noop

    if (!isDescendantOfRoot) {
      onClickOut(e)
    } else {
      onClickIn(e)
    }
  }

  const addMouseDownEventListener = capture => {
    removeMouseDown = addEventListener(document, 'mousedown', onMouseDown, { capture })
  }
  return (
    <div ref={setChildNode} {...props}>
      {children}
    </div>
  )
}

ClickOut.propTypes = {
  children: PropTypes.node.isRequired,
  onClickIn: PropTypes.func,
  onClickOut: PropTypes.func.isRequired,
  useCapture: PropTypes.bool,
}

ClickOut.defaultProps = {
  onClickIn: noop,
  useCapture: true,
}

export default ClickOut
